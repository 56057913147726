<template>
	<div class="logoHeader" :class="type==1?'borderTop':'borderBottom' ">
		<div class="container nav-con">
			<router-link to="/index">
				<img src="../../assets/img/headerlogo.png" alt="">
			</router-link>
		</div>
	</div>
</template>

<script>
	export default{
		props: ['type'],
		dtat(){
			return{
				
			}
		},
	}
</script>

<style lang="less" scoped>
	.logoHeader{
		width: 100%;
		background: #fff;
		.nav-con{
			height: 90px;
			line-height: 90px;
			img{
				height: 60px;
			}
		}
	}
	.borderTop{
		border-top: solid 1px #C1393C;
	}
	.borderBottom{
		border-bottom: solid 1px #f24b53;
	}
</style>
